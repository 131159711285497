<template>
  <div id="container">
    <div class="firstProductBoard" v-for="productFirstCategory in productWithCategory" :key="productFirstCategory.name">
      <div class="title  title1"><h2>{{ productFirstCategory.name }}</h2></div>
      <div class="secondProductBoard" v-for="productSecondCategory in productFirstCategory.secondProductCategories"
           :key="productSecondCategory.name">
        <div class="title  title1"><h3 style="color: grey">{{ productSecondCategory.name }}</h3></div>
        <div class="productBoard">
          <div class="productItem" v-for="product in productSecondCategory.products" :key="product.id">
            <div class="title  title16" style="font-weight: bolder">{{ product.name }}</div>
            <div class="productSlogan" v-html="product.slogan"></div>
            <el-divider style="width: 90%;margin: 0 auto"></el-divider>
            <div class="billRule" v-html="buildDisplayBillRule(product)"></div>
            <div style="width: 80%;margin: 0 auto">
              <el-button style="width: 100%" type="primary" @click="gotoProductDetails(product)">立即购买</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {productBillType} from "@/common/constant";

export default {
  name: "ProductList",
  mounted() {
    this.getProductWithCategory();
  },
  data() {
    return {
      productWithCategory: null
    }
  },
  methods: {
    getProductWithCategory() {
      this.$httpUtil.get('/dayu/api/v1/product/get-product-with-category', {}).then(res => {
        this.productWithCategory = res.data;
      }, (res) => {
        console.log(res);
      });
    },
    buildDisplayBillRule(product) {
      let displayPrice = product.displayPrice;
      let billType = displayPrice.billType;
      if (productBillType.FREE === billType) {
        return `<span style="color:  #409EFF;font-size: 32px">0</span>元起`;
      } else if (productBillType.DISPOSABLE === billType) {
        // 35 元/一次性起
        return `<span style="color:  #409EFF;font-size: 32px">${displayPrice.billRulePeriods[0].price}</span>元起/一次性`
      } else {
        // 899 元/半年起
        let billRulePeriod = displayPrice.billRulePeriods[0];
        return `<span style="color:  #409EFF;font-size: 32px">${billRulePeriod.price}</span>元起/${billRulePeriod.name}`
      }
    },
    gotoProductDetails(product) {
      this.$router.push({
        path: `/home/productDetails/${product.id}`,
      })
    }
  }
}
</script>

<style scoped lang="less">
@import "@/assets/style/common.less";

#container {
  height: 100vh;

  .firstProductBoard {
    .card-item();
    display: flex;
    flex-direction: column;

    .secondProductBoard {
      display: flex;
      flex-direction: column;

      .productBoard {
        display: flex;
        justify-content: flex-start;

        .productItem {
          background-color: whitesmoke;
          width: 256px;
          min-height: 384px;
          margin-top: 8px;
          margin-left: 10%;
          border-radius: 4px;
          box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
          border: 1px solid #ebeef5;

          .productSlogan {
            margin-top: 8%;
            padding: 8px;
            min-height: 50%;
          }

          .billRule {
            width: 80%;
            margin: 0 auto;
            padding: 8px;
          }
        }
      }
    }
  }

  .title {
    height: 48px;
    font-weight: 600;
    line-height: 32px;
    padding-left: 5px;
    position: relative;
    color: #000;
    z-index: 0;
  }

  .title1 {
    padding-left: 15px;

    &:after {
      content: " ";
      width: 4px;
      height: 20px;
      background: #12a3f5;
      position: absolute;
      left: 0;
      top: 6px;
      border-radius: 2px;
    }
  }

  .title16 {
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      width: 180px;
      height: 48px;
      opacity: 0.3;
      background: linear-gradient(to right, #409EFF, #4bf15900);
    }

    &:before {
      content: " ";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
      background: linear-gradient(to right, #409EFF, rgba(255, 255, 255, 0));
    }
  }
}
</style>