<template>
  <div id="container">
    <div id="checkResult">
      <el-card class="domainCard" v-for="regionDomain in allRegionDomainList"
               :key="regionDomain.domain">
        <el-tag type="info">{{ regionDomain.regionStr }}</el-tag>
        <el-divider direction="vertical"/>
        <el-tag :type="getStatus(regionDomain)">{{ regionDomain.domain }}</el-tag>
        <el-divider direction="vertical"/>
        <el-tag type="info">{{ regionDomain.intro }}</el-tag>
      </el-card>
    </div>
  </div>
</template>

<script>
import {
  serverDomain,
} from '@/common/constant'
import {defineComponent} from 'vue';
import {useStore} from 'vuex'

export default defineComponent({
  name: 'DomainCheck',
  data() {
    return {
      store: null,
      allRegionDomainList: [],
    }
  },
  mounted() {
    this.store = useStore();
    this.getAllRegionDomainList();
  },
  methods: {
    getAllRegionDomainList() {
      this.$httpUtil.get('/dayu/api/v1/anonymous/get-all-region-domain-list', {}).then(res => {
        if (res) {
          this.allRegionDomainList = res.data;
        }
      }, res => {
        console.log(res);
      }).finally(() => {
        //
      });
    },
    getStatus(regionDomain) {
      if (regionDomain.activeFlag) {
        return "success"
      }
      return "error";
    }
  }
});
</script>

<style scoped lang="less">
@import "@/assets/style/common.less";

#container {
  display: flex;
  flex-direction: column;
  align-items: center;

  #checkResult {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .domainCard {
    width: 784px;
    max-width: 960px;
    margin-top: 8px;
  }
}

</style>
