<template>
  <iframe src="https://aomoow1hlm.feishu.cn/wiki/wikcnEgFCht7W8MsyCLlQbpI1xb"></iframe>
</template>

<script>
  export default {
    name: "Help"
  }
</script>

<style scoped>
  iframe {
    width: 100%;
    height: 100vh;
    border: none;
  }
</style>
