<template>
  <div id="container">
    <el-page-header @back="goBack" title="返回" content="产品详情页面"></el-page-header>
    <div id="productDetailsBoard">
      <div class="title title1"><h2>{{ productDetails.name }}</h2></div>
      <div id="productConfigBoard">
        <div id="productConfigParameterBoard">
          <div>
            <h3>产品配置</h3>
            <el-form label-position="left" label-width="128px" :model="productConfigParameter"
                     ref="productConfigParameterForm">
              <div v-html="productConfig.productDescription">
              </div>
              <el-form-item label="周期" prop="productPeriod" style="width: 50%">
                <el-select v-model="productConfigParameter.buyPeriod">
                  <el-option
                      v-for="item in getPeriodOptions(productConfig)"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="购买量" prop="buyQuantity" style="width: 50%">
                <el-input type="number" v-model.number="productConfigParameter.buyQuantity"
                          :max="productConfig.maxQuantity"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div id="orderPreviewBoard">
          <h3>订购汇总</h3>
          <div>购买周期：{{ getPeriodOptionsMap(this.productConfig)[productConfigParameter.buyPeriod] }}</div>
          <div>购买数量：{{ productConfigParameter.buyQuantity }}</div>
          <el-divider style="width: 90%;margin: 8px auto"></el-divider>
          <el-button style="width: 100%;margin-top: 8px" type="primary" @click="addToShoppingCart">添加到购物车
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {productBillType} from "@/common/constant";

export default {
  name: "ProductDetails",
  mounted() {
    let productId = this.$route.params.productId;
    if (productId) {
      this.productId = parseInt(productId);
    }
    this.getProductDetails(productId);
  },
  data() {
    return {
      productId: null,
      productDetails: {},
      productConfig: {},
      productConfigParameterMap: {},
      productConfigParameter: {}
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    getPeriodOptions(productConfig) {
      if (!productConfig.billRule) {
        return [];
      }
      return productConfig.billRule.billRulePeriods
          .filter(x => x.enabled)
          .map(x => {
            return {
              'label': x.name,
              'value': x.periodType
            }
          });
    },
    getPeriodOptionsMap(productConfig) {
      if (!productConfig) {
        return {}
      }
      if (!productConfig.billRule) {
        return {};
      }
      let periodOptionsMap = {}
      productConfig.billRule.billRulePeriods
          .filter(x => x.enabled)
          .forEach(x => {
            periodOptionsMap[x.periodType] = x.name;
          });
      return periodOptionsMap;
    },
    getParameterLabel(code) {
      let param = this.productConfigParameterMap['code'];
      if (!param) {
        return "";
      }
      return param.name;
    },
    addToShoppingCart() {
//
    },
    getProductDetails(productId) {
      this.$httpUtil.get('/dayu/api/v1/product/get-product-details', {
        productId
      }).then(res => {
        this.productDetails = res.data;
        this.productConfig = res.data.productConfig;
        this.productConfig.configParameters.forEach(row => {
          this.productConfigParameterMap[row.code] = row;
          this.productConfigParameter[row.code] = row.value;
        });
      }, (res) => {
        console.log(res);
      });
    },
  }
}
</script>

<style scoped lang="less">
@import "@/assets/style/common.less";

#container {
  height: 100vh;

  #productDetailsBoard {
    .card-item();

    #productConfigBoard {
      display: flex;

      #productConfigParameterBoard {
        .card-item();
        width: 60%;
      }

      #orderPreviewBoard {
        .card-item();
        width: 40%;
      }
    }
  }

  .title {
    height: 48px;
    font-weight: 600;
    line-height: 32px;
    padding-left: 5px;
    position: relative;
    color: #000;
    z-index: 0;
  }

  .title1 {
    padding-left: 15px;

    &:after {
      content: " ";
      width: 4px;
      height: 20px;
      background: #12a3f5;
      position: absolute;
      left: 0;
      top: 6px;
      border-radius: 2px;
    }
  }

  .title16 {
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      width: 180px;
      height: 48px;
      opacity: 0.3;
      background: linear-gradient(to right, #409EFF, #4bf15900);
    }

    &:before {
      content: " ";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
      background: linear-gradient(to right, #409EFF, rgba(255, 255, 255, 0));
    }
  }
}
</style>